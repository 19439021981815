var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
Backbone.View.fullExtend = require('../utils/Backbone.fullExtend');
var Velocity = require('velocity-animate');

var ImageView = require('./galleryImage');

var imgSize = require('../utils/imgSize');
var breakpoints = require('../utils/breakpoints');

var GalleryBase = Backbone.View.extend({
    events: {
        'click': 'clickHandler',
    },

    className: 'gallery',

    captionTemplate: _.template('<h3><%= imageIndex %></h3>\
        <div class="captionDetails">\
            <span class="captionTitle"><%= caption %></span>\
            <% if(altTitle && altTitle.length > 0){ %><span class="captionInfo"><%= altTitle %></span><% } %>\
        </div>'),
    
    initialize: function(opts){
        _.bindAll(this, 'touchstartHandler', 'touchmoveHandler', 'touchendHandler', 'keyHandler', 'dragstartHandler', 'mousedownHandler', 'mousemoveHandler', 'mouseupHandler');

        this.parent = opts.parent;

        this.collection = new Backbone.Collection(opts.data);
        this.model = new Backbone.Model({
            cid: this.collection.at(opts.start).get('id'),
            breadcrumbs: false,
            infinite: opts.infinite,
            hasDragged: false
        });
        this.images = [];

        if(this.collection.length > 1){
            if(JS.view.isTouch()){
                this.$el.on('touchstart', this.touchstartHandler);
                this.$el.on('touchmove', this.touchmoveHandler);
                this.$el.on('touchend', this.touchendHandler);
                this.$el.on('touchcancel', this.touchendHandler);
            } else {
                this.$el.on('dragstart', this.dragstartHandler);
                this.$el.on('mousedown', this.mousedownHandler);
                this.$el.on('mousemove', this.mousemoveHandler);
                this.$el.on('mouseup', this.mouseupHandler);
                this.$el.on('mousecancel', this.mouseupHandler);
                this.$el.on('mouseleave', this.mouseupHandler);
                $(window).on('keydown', this.keyHandler);
            }
        }

        this.model.on({
            'change:cid': this.changePost
        }, this);

        JS.pubSub.on('app:resize', this.resize, this);

        this.initThis(opts);
    },

    initThis: function(){},

    changePost: function(model, postId){
        var that = this;
        var $el;
        var currentModel = this.collection.get(this.model.get('cid'));

        var time = 50;
        if(this.$('.caption').length){
            time = 800;
            var $oldEl = this.$('.caption');
            $oldEl.addClass('out');
            setTimeout(function(){
                $oldEl.remove();
            }, time);
        }

        var data = currentModel.toJSON();
        data.imageIndex = this.padNumber(this.collection.indexOf(currentModel) + 1, 2);
        $el = $('<div class="label caption out"></div>');
        $el.html(this.captionTemplate(data));
        this.$el.append($el);
        
        setTimeout(function(){
            if($el){
                $el.removeClass('out');
            }
        }, time);
    },

    padNumber: function (n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    },

    touchstartHandler: function(e){
        //e.preventDefault();

        var x = e.originalEvent.touches[0].clientX;
        this.dragStart(x);

        this.model.set('touchYStart', e.originalEvent.touches[0].clientY);
        this.model.set('touchYMoved', false);
    },

    touchmoveHandler: function(e){
        var x = e.originalEvent.touches[0].clientX;
        var y = e.originalEvent.touches[0].clientY;

        if(!this.model.get('touchMoved') && Math.abs(y - this.model.get('touchYStart')) > 10){
            this.model.set('touchYMoved', true);
        }
        
        if(!this.model.get('touchYMoved')){
            this.dragMove(x);

            if(Math.abs(this.model.get('touchX') - this.model.get('touchXStart')) > 10){
                e.preventDefault();
            }
        }
    },

    touchendHandler: function(e){
        //e.preventDefault();
        this.model.set('touchYMoved', false);
        this.dragEnd();
    },

    dragstartHandler: function(e){
        e.preventDefault();
        e.stopPropagation();
    },

    mousedownHandler: function(e){
        e.preventDefault();

        var x = e.pageX;
        this.dragStart(x);
    },

    mousemoveHandler: function(e){
        if(!this.model.get('touched')){ return; }

        var x = e.pageX;
        this.dragMove(x);
    },

    mouseupHandler: function(e){
        if(!this.model.get('touched')){ return; }
        this.dragEnd();
    },

    dragStart: function(x){
        this.model.set({
            'touchXStart': x,
            'touchX': x,
            'touched': true,
            'touchMoved': false
        });
    },

    dragMove: function(nX){
        var x = this.model.get('touchXStart');
        this.model.set('touchX', nX);

        this.model.set('hasDragged', true)
        
        var dist = nX - x;
        var perc = dist / JS.view.w;

        this.updateForPercentage(perc * 0.75);

        if(Math.abs(dist) > 20){
            this.model.set('touchMoved', true);
        }
    },

    dragEnd: function(){
        var that = this;
        var x = this.model.get('touchXStart');
        var nX = this.model.get('touchX');
        var mult = nX - x;
        var dir;
        if(Math.abs(mult) < 10){
            this.model.set('hasDragged', false);
        } else{
            if(Math.abs(mult) < 75){
                this.animateImages();
            } else {
                if(mult < 0){
                    dir = 'next';
                } else {
                    dir = 'prev';
                }
                this.navigateDirection(dir);
            }
            setTimeout(function(){
                that.model.set('hasDragged', false);
            }, 300);
        }
        this.model.set('touched', false);
    },

    keyHandler: function(e){
        if(this.isAnimating){ return; }
        if(e.which == 37){
            this.navigateDirection('prev');
        } else if(e.which == 39){
            this.navigateDirection('next');
        }
        this.keyHandlerThis(e);
    },

    keyHandlerThis: function(e){},

    clickHandler: function(e){
        if(this.isAnimating || this.model.get('hasDragged')){ return; }
        e.preventDefault();
        e.stopPropagation();
        var x = e.pageX;
        var dir;
        if(x < JS.view.w/3){
            dir = 'prev';
        } else {
            dir = 'next';
        }
        this.navigateDirection(dir);
    },

    bttnHandler: function(e){
        if(this.isAnimating){ return; }
        e.preventDefault();
        e.stopPropagation();

        var dir;
        if($(e.currentTarget).hasClass('prev')){
            dir = 'prev';
        } else {
            dir = 'next';
        }
        this.navigateDirection(dir);
    },

    breadcrumbHandler: function(e){
        e.preventDefault();
        e.stopPropagation();

        var $e = $(e.currentTarget);
        var index = $e.closest('.breadcrumbs').find('.crumb').index($e);

        this.navigateToIndex(index);
    },

    navigateToIndex: function(i){
        if(this.isAnimating){ return; }

        var model = this.collection.at(i);
        if(!model){ return; }

        var id = model.get('id');
        this.navigateToID(id);
    },

    navigateDirection: function(dir){
        if(this.isAnimating){ return; }

        var id = this.getId(dir);
        this.navigateToID(id);
    },

    navigateToID: function(id){
        if(!_.isUndefined(id)){
            this.resolveImages(id);
            this.animateImages();
        }
    },

    render: function(){
        if(this.model.get('infinite')){
            this.$el.addClass('infinite');
        }

        this.resolveImages(this.model.get('cid'));

        this.changePost(this.model, this.model.get('cid'));
        return this;
    },

    resolveImages: function(id){
        var that = this;
        this.model.set('cid', parseInt(id));
        id = parseInt(id);
        var ids = [id, this.getId('prev'), this.getId('next')];
        _.each(ids, function(_id){
            if(!_.isUndefined(_id)){
                var _img = that.collection.findWhere({id: _id});
                var _view = that.appendOrReturn(_img.id);
            }
        });

        var toPop = [];
        _.each(this.images, function(_image, _i){
            if(!_.contains(ids, parseInt(_image.id))){
                _image.view.remove();
                toPop.push(_i);
            }
        });
        _.each(toPop.reverse(), function(_c){
            that.images.splice(_c,1);
        });
    },

    animateImages: function(jump){
        var that = this;

        var id = this.model.get('cid');
        var currentView = this.appendOrReturn(id);
        if(!jump && currentView.appended){
            this.setVelocity(currentView, 'previous', false);
            currentView.appended = false;
        } else {
            currentView.appended = false;
        }
        currentView.$el.removeClass('imgOut prevImg nextImg');
        var currentPromise = this.setVelocity(currentView, 'current', !jump);

        var prevId, prevView, prevPromise;
        prevId = this.getId('prev');
        if(this.collection.length > 1 && !_.isUndefined(prevId)){
            prevView = this.appendOrReturn(prevId);
            prevView.$el.removeClass('nextImg').addClass('imgOut prevImg');
            this.$el.removeClass('first');
        
            if(!jump && prevView.appended){
                this.setVelocity(prevView, 'previousOut', false);
                prevView.appended = false;
            } else {
                prevView.appended = false;
            }
    
            prevPromise = this.setVelocity(prevView, 'previous', !jump);
        } else {
            this.$el.addClass('first');
        }
        
        var nextId, nextView, nextPromise;
        nextId = this.getId('next');
        if(this.collection.length > 1 && !_.isUndefined(nextId)){
            nextView = this.appendOrReturn(nextId);
            nextView.$el.removeClass('prevImg').addClass('imgOut nextImg');
            this.$el.removeClass('last');
        
            if(!jump && nextView.appended){
                this.setVelocity(nextView, 'nextOut', false);
                nextView.appended = false;
            } else {
                nextView.appended = false;
            }
            
            nextPromise = this.setVelocity(nextView, 'next', !jump);
        } else {
            this.$el.addClass('last');
        }

        var h = this.getCurrentHeight();
        if(jump || !JS.view.isMobile() || !JS.view.isLandscape()){
            Velocity.hook(this.$el, 'height', h);
        } else {
            Velocity(this.$el, {
                height: h
            }, {
                duration: 700
            });
        }

        if(!jump){
            this.isAnimating = true;

            if(JS.view.isMobile() && JS.view.isLandscape()){
                $('body, html').animate({
                    scrollTop: 0
                }, 700);
            }

            $.when(currentPromise, nextPromise, prevPromise).done(function(){
                that.$el.removeClass('animating');
                that.isAnimating = false;
                that.resolveImages(that.model.get('cid'));
                that.animateImages(true);
            });
        } else {
            
        }
    },

    setVelocity: function(imageView, state, animate){
        var styleOpts = {};
        var animationOpts = {};
        var promise = $.Deferred();

        var opts = this.getOptionsForState(state);

        styleOpts.width = opts.width+'px';
        styleOpts.translateX = opts.translateX+'px';
        //styleOpts.opacity = opts.opacity;

        switch(state){
            case 'current':
                if(!this.model.get('hasDragged')){
                    animationOpts.delay = 400;
                }
                break;
            case 'previous':
                break;
            case 'next':
                break;
            case 'previousOut':
                animationOpts.duration = 400;
                break;
            case 'nextOut':
                animationOpts.duration = 400;
                break;
        }

        if(animate){
            Velocity(imageView.$el, styleOpts, _.extend({}, {
                duration: 1600,
                complete: function(){
                    promise.resolve();
                }
            }, animationOpts));
        } else {
            _.each(styleOpts, function(_opt, _key){
                Velocity.hook(imageView.$el, _key, _opt);
            });
            promise.resolve();
        }

        return promise.promise();
    },

    getOptionsForState: function(state){
        var opts = {
            width: 0,
            translateX: 0,
            opacity: 0
        }

        var gutter = this.getGutterWidth();
        var sideWidth = this.getSideWidth();

        opts.width = (JS.view.w - (gutter * 2));

        switch(state){
            case 'current':
                //opts.width = (JS.view.w - (gutter * 2));
                opts.translateX = gutter;
                opts.opacity = 1;
                break;
            case 'previous':
                //opts.width = sideWidth;
                //opts.translateX = 0;
                opts.opacity = 0.5;
                opts.translateX = 0 - opts.width + sideWidth;
                break;
            case 'next':
                //opts.width = sideWidth;
                opts.translateX = (JS.view.w - sideWidth);
                opts.opacity = 0.5;
                break;
            case 'previousOut':
                //opts.width = sideWidth;
                //opts.translateX = -sideWidth;
                opts.translateX = 0 - opts.width + gutter - sideWidth;
                opts.opacity = 0.5;
                break;
            case 'nextOut':
                //opts.width = sideWidth;
                opts.translateX = JS.view.w + opts.width;
                opts.opacity = 0.5;
                break;
        }

        return opts;
    },

    updateForPercentage: function(perc){
        var currentImageFrom = this.getOptionsForState('current');
        var previousImageFrom = this.getOptionsForState('previous');
        var nextImageFrom = this.getOptionsForState('next');

        if(perc > 0){
            currentImageTo = this.getOptionsForState('next');
            previousImageTo = this.getOptionsForState('current');
            nextImageTo = this.getOptionsForState('nextOut');
        } else {
            currentImageTo = this.getOptionsForState('previous');
            previousImageTo = this.getOptionsForState('previousOut');
            nextImageTo = this.getOptionsForState('current');
        }
        var $currentImage = this.$('.image:not(.imgOut)');
        var $prevImage = this.$('.image.prevImg');
        var $nextImage = this.$('.image.nextImg');

        this.updateImageForPercentage($currentImage, currentImageFrom, currentImageTo, perc);
        this.updateImageForPercentage($prevImage, previousImageFrom, previousImageTo, perc * 0.5);
        this.updateImageForPercentage($nextImage, nextImageFrom, nextImageTo, perc * 0.5);
    },

    updateImageForPercentage: function($el, fromStyle, toStyle, perc){
        var styleOpts = {
            width: Math.round(this.getValueForPercentage(fromStyle.width, toStyle.width, perc) * 100) / 100 + 'px',
            translateX: this.getValueForPercentage(fromStyle.translateX, toStyle.translateX, perc) + 'px',
            //opacity: this.getValueForPercentage(fromStyle.opacity, toStyle.opacity, perc)
        };

        _.each(styleOpts, function(_opt, _key){
            Velocity.hook($el, _key, _opt);
        });
    },

    getValueForPercentage: function(from, to, perc){
        return from + ((to - from) * Math.abs(perc));
    },

    getGutterWidth: function(){
        var w = 40;
        if(JS.view.w <= breakpoints.mobile){
            w = 20;
        }
        return w;
    },

    getSideWidth: function(){
        var w = 15;
        if(JS.view.w <= breakpoints.mobile){
            w = 8;
        }
        return w;
    },

    getId: function(direction){
        var id, index, adjacentModel;

        var currentImg = this.collection.findWhere({id: this.model.get('cid')});
        var imgIndex = this.collection.indexOf(currentImg);

        adjacentModel = this.returnModel(direction, imgIndex);
        
        if(adjacentModel){
            id = adjacentModel.get('id');
        }

        return id;
    },

    returnModel: function(direction, index){
        var model;
        var i = index;
        if(direction == 'prev'){
            i--;
        } else {
            i++;
        }
        if(i < 0 || i >= this.collection.length){
            if(!this.model.get('infinite')){
                return false;   
            } else if(i < 0){
                model = this.collection.at(this.collection.length - 1);
            } else {
                model = this.collection.at(0);
            }
        } else {
            model = this.collection.at(i);
        }

        return model;
    },

    appendOrReturn: function(id){
        var view;
        var existing = _.findWhere(this.images, {id: parseInt(id)});
        var model = this.collection.findWhere({id: parseInt(id)});

        if(existing){
            view = existing.view;
        } else {
            view = this.returnImageView({model: model, parent: this});
            this.imageContainer().append(view.render().$el);
            view.resize();
            view.appended = true;
            this.images.push({id: id, view: view});
        }

        return view;
    },

    imageContainer: function(){
        return this.$el;
    },

    returnImageView: function(opts){
        return new ImageView(opts);
    },

    resize: function(){
        var w = JS.view.w;
        var h = this.getCurrentHeight();

        this.$el.css({'width': w, 'height': h});
        this.animateImages(true);
    },

    getCurrentHeight: function(){
        var h = 0;

        if(JS.view.isMobile() && JS.view.isLandscape()){
            var gutterWidth = this.getGutterWidth();
            var imageWidth = JS.view.w - (gutterWidth * 2);

            var cid = this.model.get('cid');
            var currentView = this.appendOrReturn(cid);
            var size = imgSize({
                imgW: currentView.model.get('width'),
                imgH: currentView.model.get('height'),
                width: imageWidth
            });

            h = Math.max(h, size.height + 94, JS.view.h);
        } else {
            h = JS.view.h;
        }

        return h;
    },

    remove: function(){
        this.removeThis();

        if(JS.view.isTouch()){
            this.$el.off('touchstart', this.touchstartHandler);
            this.$el.off('touchmove', this.touchmoveHandler);
            this.$el.off('touchend', this.touchendHandler);
            this.$el.off('touchcancel', this.touchendHandler);
        } else {
            this.$el.off('dragstart', this.dragstartHandler);
            this.$el.off('mousedown', this.mousedownHandler);
            this.$el.off('mousemove', this.mousemoveHandler);
            this.$el.off('mouseup', this.mouseupHandler);
            this.$el.off('mousecancel', this.mouseupHandler);
            this.$el.off('mouseleave', this.mouseupHandler);
            $(window).off('keydown', this.keyHandler);
        }
        
        JS.pubSub.off('app:resize', this.resize);

        _.each(this.images, function(_image){
            if(_image.view){
                _image.view.remove();
            }
        })

        this.undelegateEvents();
        this.$el.remove();
    },

    removeThis: function(){},
});

module.exports = GalleryBase;