var $ = jQuery = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
Backbone.View.fullExtend = require('../utils/Backbone.fullExtend');
var Velocity = require('velocity-animate');

var imgSize = require('../utils/imgSize');
var breakpoints = require('../utils/breakpoints');
var preloadImages = require('../utils/preloadImages');

var PageView = Backbone.View.extend({
    events: {},

    initialize: function(){
        var that = this;

        this.model = new Backbone.Model();

        this.pubSub = _.extend({}, Backbone.Events);
        this.pubSub.on('page:loaded', this.pageLoaded, this);

        this.initThis();
    },

    initThis: function(){
        return false;
    },

    animateIn: function(){
        var that = this;
        var promise = $.Deferred();
        
        this.$el.addClass('viewIn');
        setTimeout(function(){
            promise.resolve();
            that.pubSub.trigger('page:loaded');
        }, 800);
        return promise.promise();
    },

    animateOut: function(){
        var promise = $.Deferred();

        this.pubSub.trigger('page:removing');
        this.$el.removeClass('viewIn pageLoaded');
        setTimeout(function(){
            promise.resolve();
        }, 800);
        return promise.promise();
    },

    pageLoaded: function(){
        this.$el.addClass('pageLoaded');
    },

    getGalleryScope: function($el){
        var $scope = $el.closest('.galleryScope');
        if(!$scope){
            $scope = this.$el;
        }
        return $scope;
    },

    getGalleryIndex: function($scope, $e){
        return $scope.find('.galleryObj').index($e);
    },

    buildGalleryArray: function($scope){
        var array = [];
        $scope.find('.galleryObj').each(function(i){
            var $obj = $(this);

            var width = parseInt($obj.attr('img-width'));
            var height = parseInt($obj.attr('img-height'));
            
            array.push({
                id:         parseInt($obj.attr('img-id')),
                pid:        parseInt($obj.attr('img-pid')),
                src:        $obj.attr('img-src'),
                width:      width,
                height:     height,
                caption:    $obj.attr('img-caption'),
                altTitle:    $obj.attr('img-alt')
            });
        });

        return array;
    },

    remove: function(){
        this.pubSub.trigger('page:removed');
        this.undelegateEvents();
        this.$el.remove();
    },

    fixedHeight: false
});

module.exports = PageView;