var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
var Velocity = require('velocity-animate');

var pages = require('./pages');
var HeaderView = require('./components/header');

var breakpoints = require('./utils/breakpoints');

var AppView = Backbone.View.extend({

    el: 'body',

    events: {
        'click a': 'linkHandler',
        'click .headerCatchTouch': 'headerCatchTouchHandler'
    },

    initialize: function(){
    	_.bindAll(this, 'resize', 'scroll');
        $(window).on('resize', this.resize);
        $(window).on('scroll', this.scroll);

        JS.pubSub.on({
            'app:resize:start': this.resizeStart,
            'app:resize:end': this.resizeEnd
        }, this);

    	this.model = new Backbone.Model({
            'isTouch': false,
            'isFirefox': navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
            'isLandscape': false,
            'isMobile': false,
            'containerOffset': 0
    	});

        if('ontouchstart' in document.documentElement){
            this.model.set('isTouch', true);
            this.$el.addClass('isTouch');
        }

        if(this.isFirefox()){
            this.$el.addClass('isFirefox');
        }

        this.model.on({
            'change:isResizing': this.isResizingToggle,
            'change:containerOffset': this.containerOffsetUpdate
        }, this);
    },

    isTouch: function(){
        return this.model.get('isTouch');
    },

    isFirefox: function(){
        return this.model.get('isFirefox');
    },

    isMobileMenu: function(){
        return this.w <= breakpoints.tabletPortrait;
    },

    isLandscape: function(){
        return this.model.get('isLandscape');
    },

    isMobile: function(){
        return this.model.get('isMobile');
    },

    render: function(){
    	this.headerView = new HeaderView();

    	this.resize();
    	this.scroll();
    },

    load: function(page){
        var that = this;

        var href = this.currentUrl();
        this.headerView.delegateHeader(Backbone.history.fragment);
        this.headerView.model.set('expanded', false);

        if(!this.view){
            var $el = this.$('.page');
            if($el){
                this.view = this.delegatePage($el);
                this.view.animateIn(true);
                //that.postLoadScroll();
            }
        } else {

            var promise, $content;

            var oldView = this.view;
            promise = oldView.animateOut();

            var ajax = $.ajax({
                url: '/'+Backbone.history.fragment,
                error: function (xhr, ajaxOptions, thrownError){
                    var errorHref = window.location.protocol+'//'+window.location.hostname;
                    if(window.location.port){
                        errorHref += ':'+window.location.port;
                    }
                    if(xhr.status == 404) {
                        window.location.replace(errorHref+'/'+Backbone.history.fragment);
                    } else {
                        window.location.href = errorHref+'/'+Backbone.history.fragment;
                    }
                },
                success: function(data, textStatus, xhr){
                    if(_.isObject(xhr.responseJSON) && xhr.responseJSON.external_redirect){
                        window.location.replace(xhr.responseJSON.url);
                        return;
                    }

                    var $html = $('<div></div>').append(data);
                    document.title = $html.find('title:eq(0)').text();
                    $content = $(data).find('.page');
                }
            });

            $.when(promise).done(function(){
                oldView.remove();
                $('body, html').scrollTop(0);
            });

            $.when(promise, ajax).done(function(){
                that.$('#container').html('');

                $('body, html').scrollTop(0);
                that.$('#container').append($content);
                
                var $el = that.$('.page');
                that.view = that.delegatePage($el);
                setTimeout(function(){
                    that.view.animateIn();
                    JS.pubSub.trigger('app:scroll');
                    //that.postLoadScroll();
                }, 50);
            });
        }
    },

    currentUrl: function(){
        return JS.homeUrls[0]+'/'+Backbone.history.fragment;
    },

    delegatePage: function($el){
        var controller = $el.attr('view-controller');
        if(_.isUndefined(pages[controller])){
            controller = 'Default';
        }
        return new pages[controller]({el: $el});
    },

    linkHandler: function(e, _href){
        var $el, href;

        if(!e && _href){
            href = _href;
        } else {
            $el = $(e.currentTarget);
            href = $el.attr('href');
        }

        var targetHref, internalUrl;

        _.each(JS.homeUrls, function(_url){
            if(internalUrl){ return; }
            var strLength = _url.length;
            if(href.substr(0,strLength)==_url && (href.indexOf('wp-content')<0 && href.indexOf('uploads')<0)){
                targetHref = href.substr(strLength);
                internalUrl = true;
            }
        });
        if(internalUrl){
            if(e){
                e.preventDefault();
                e.stopPropagation();
            }
            this.navigateToPage(targetHref);
        }
    },

    navigateToPage: function(href){
        JS.router.navigate(href, {trigger: true});
    },

    resize: _.throttle(function(){
        if(this.resizeTO){ clearTimeout(this.resizeTO); }
        if(!this.model.get('isResizing')){
            JS.pubSub.trigger('app:resize:start');
        }

        this.w = $(window).width();
        this.h = $(window).height();

        this.model.set('isLandscape', this.w > this.h);
        this.model.set('isMobile', this.w <= breakpoints.mobileLandscape);

        this.resizeTO = setTimeout(function(){
            JS.pubSub.trigger('app:resize:end');
        }, 250);
        JS.pubSub.trigger('app:resize');
    }, 16),

    resizeStart: function(){
        this.model.set('isResizing', true);
    },

    resizeEnd: function(){
        this.model.set('isResizing', false);
    },

    isResizingToggle: function(model, resizing){
        this.$el.toggleClass('isResizing', resizing);
    },

    scroll: function(){
    	var scrollPos = $(window).scrollTop();
    	
    	this.scrollPos = scrollPos;
        JS.pubSub.trigger('app:scroll');
    },

    scrollbarWidth: function(){
        var outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.width = "100px";
        outer.style.msOverflowStyle = "scrollbar";

        document.body.appendChild(outer);

        var widthNoScroll = outer.offsetWidth;
        outer.style.overflow = "scroll";

        var inner = document.createElement("div");
        inner.style.width = "100%";
        outer.appendChild(inner);        

        var widthWithScroll = inner.offsetWidth;

        outer.parentNode.removeChild(outer);

        return widthNoScroll - widthWithScroll;
    },

    containerOffsetUpdate: function(model, offset){
        var time = 800;
        if(this.headerView && this.headerView.model.get('homeLayout')){
            time = 0;
        }
        
        Velocity(this.$('#container'), {
            translateY: offset
        }, {
            duration: time,
            queue: false
        });
    },

    headerCatchTouchHandler: function(e){
        this.headerView.model.set('expanded', false);
    }

});

module.exports = AppView;