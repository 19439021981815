var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
var Velocity = require('velocity-animate');

var _basePage = require('./_basePage');

var preloadImages = require('../utils/preloadImages');
var imgSize = require('../utils/imgSize');

var Home = _basePage.fullExtend({
    
    imageTemplate: _.template('<div class="image"><img src="<%= src %>" width="<%= width %>" height="<%= height %>" /></div>'),

    initThis: function(){
        var that = this;

        this.pubSub.on('page:loaded', this.startShow, this);
        this.pubSub.on('page:removed', this.removeThis, this);
        JS.pubSub.on('app:resize', this.resizePage, this);

        this.listenTo(JS.view.headerView.model, {
            'change:activeLink': this.toggleActiveLink
        }, this)

        this.model = new Backbone.Model({
            index: 0
        });

        this.$('.galleryBuild:eq(0)').each(function(){
            var $scope = $('.galleryScope', this);
            that.images = new Backbone.Collection(that.buildGalleryArray($scope));
            $scope.remove();
            that.randomiseCollection(that.images);
            that.loadImage(0);
        });

        this.resizePage();
    },

    animateIn: function(){
        var that = this;
        var promise = $.Deferred();

        JS.view.headerView.model.set('homeLayout', true);
        
        this.$el.addClass('viewIn');
        
        this.$('.imageContent').append('<div class="jsLogoAnimated"></div>');
        var $logo = this.$('.jsLogoAnimated');

        var imageloadPromise = $.Deferred();
        var logoUrl = $logo.css('background-image').replace( /url\(|\)/g, '' );
        preloadImages([logoUrl]).done(function(){
            imageloadPromise.resolve();
        })

        $.when(imageloadPromise.promise()).done(function(){

            Velocity($logo, {
                opacity: [0.35, 0],
                translateY: ['0px', '-60px']
            }, {
                display: 'block',
                duration: 1000,
                easing: [0.385, 0.090, 0.135, 0.985],
                complete: function(){
                    Velocity($logo, {
                        opacity: [0, 0.35],
                        translateY: ['60px', '0px']
                    }, {
                        display: 'none',
                        duration: 1000,
                        delay: 50,
                        easing: [0.385, 0.090, 0.135, 0.985],
                        complete: function(el){
                            $(el).remove();
                            JS.view.headerView.model.set('homeLayoutVisible', true);

                            setTimeout(function(){
                                promise.resolve();
                                that.pubSub.trigger('page:loaded');
                            }, 500);
                        }
                    });
                }
            });

        });

        return promise.promise();
    },

    animateOut: function(){
        var promise = $.Deferred();

        JS.view.headerView.model.set('homeLayoutOut', true);

        this.pubSub.trigger('page:removing');
        this.$el.removeClass('viewIn pageLoaded');

        setTimeout(function(){
            JS.view.headerView.model.set('homeLayout', false);
            promise.resolve();
        }, 800);

        return promise.promise();
    },

    randomiseCollection: function(collection){
        collection.reset(collection.shuffle(), {silent:true});
    },

    loadImage: function(index, callback){
        var image = this.images.at(index);
        if(image){
            preloadImages([image.get('src')]).done(function(){
                if(callback){
                    callback();
                }
            });
        }
    },

    startShow: function(){
        this.newImage(this.model.get('index'));
    },

    removeImages: function(){
        Velocity(this.$('.image'), 'stop', true);
        Velocity(this.$('.image img'), 'stop', true);
        this.$('.image').remove();
    },

    nextImage: function(){
        var index = this.model.get('index');
        index ++;
        
        if(index >= this.images.length){
            index = 0;
            this.randomiseCollection(this.images);
        }

        this.model.set('index', index);
        this.newImage(index);
    },

    newImage: function(index, jump){
        var that = this;
        
        this.removeImages();

        var image = this.images.at(index);
        var $image = $(this.imageTemplate(image.toJSON()));
        
        this.imageTO = setTimeout(function(){
            that.resizeImage($image);
            that.$('.imageContent').append($image);

            var promise = that.animateImage($image, jump);
            if(!jump){
                $.when(promise).done(function(){
                    that.nextImage();
                });
            }
        }, 10);
    },

    animateImage: function($el, jump){
        var promise = $.Deferred();

        var $image = $el.find('img');

        Velocity($el, {
            opacity: [1, 0]
        }, {
            display: 'block',
            duration: jump ? 300 : 1200,
            queue: false,
            complete: function(){
                if(!jump){
                    Velocity($el, {
                        opacity: [0, 1]
                    }, {
                        display: 'none',
                        duration: 900,
                        delay: 3800,
                        queue: false
                    });
                }
            }
        });

        if(!jump){
            Velocity($image, {
                scale: [1.1, 1]
            }, {
                duration: 6000,
                easing: 'linear',
                complete: function(){
                    promise.resolve();
                }
            });
        }

        return promise.promise();
    },

    showImage: function(){
        this.$('.image')
    },

    resizePage: function(){
        this.$el.css({'width': JS.view.w, 'height': JS.view.h});

        if(this.$('.image').length > 0){
            this.resizeImage(this.$('.image'));
        }
    },

    resizeImage: function($el){
        imgSize({
            $el: $el.find('img'),
            width: JS.view.w,
            minHeight: JS.view.h,
            midX: true,
            midY: true
        });
    },

    toggleActiveLink: function(model, id){
        if(!_.isNumber(id)){
            this.nextImage();
        } else {
            var index;
            var image = this.images.findWhere({pid: id});
            if(image){
                index = this.images.indexOf(image);
            } else {
                index = Math.floor(Math.random() * this.images.length);
            }
            this.newImage(index, true);
        }
    },

    removeThis: function(){
        if(this.imageTO){ clearTimeout(this.imageTO); }

        this.pubSub.off('page:loaded', this.startShow);
        JS.pubSub.off('app:resize', this.resizePage);
    },

    fixedHeight: true
});

module.exports = Home;