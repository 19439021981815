var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
var Velocity = require('velocity-animate');

var breakpoints = require('../utils/breakpoints');

var HeaderView = Backbone.View.extend({
    el: 'header',

    events: {
        'click': 'headerClickHandler',
        'click a': 'headerAClickHandler',
        'mouseenter': 'headerActiveMouseHandler',
        'mouseleave': 'headerInactiveMouseHandler',
        'mousemove a.galleryLink': 'galleryActiveMouseHandler',
        'mouseleave a.galleryLink': 'galleryInactiveMouseHandler',
        'click .hasDropdown a': 'dropdownAClickHandler',
        'mouseenter .hasDropdown': 'dropdownOpenMouseHandler',
        'mouseleave .hasDropdown': 'dropdownCloseMouseHandler',
        'click .mobileMenuToggle': 'mobileMenuToggleHandler'
    },

    initialize: function(){
        JS.pubSub.on('app:resize', this.resizeHeader, this);

        this.model = new Backbone.Model({
            'homeLayout': false,
            'homeLayoutVisible': false,
            'homeLayoutOut': false,
            'activeLink': false,
            'expanded': false
        });
        this.model.on({
        	'change:homeLayout': this.toggleHomeLayout,
            'change:homeLayoutVisible': this.toggleHomeLayoutVisible,
            'change:homeLayoutOut': this.toggleHomeLayoutOut,
            'change:activeLink': this.toggleActiveLink,
            'change:expanded': this.toggleExpanded
        }, this);

        this.setHeight(this.getHeight(), true);
    },

    delegateHeader: function(path){
    },

    toggleExpanded: function(model, expanded){
        this.$el.toggleClass('expanded', expanded);
        if(!expanded){
            this.removeOpenDropdowns();
        }

        this.setHeight(this.getHeight());
    },

    headerClickHandler: function(){
        if(!this.model.get('homeLayout')){
            this.model.set('expanded', !this.model.get('expanded'));
        }
    },

    headerAClickHandler: function(e){
        if(!this.model.get('homeLayout') && !this.model.get('expanded')){
            e.preventDefault();
            e.stopPropagation();

            this.model.set('expanded', true);
        }
    },

    headerActiveMouseHandler: function(e){
        if(JS.view.isTouch() || JS.view.isMobileMenu()){ return; }
        if(this.model.get('homeLayout')){ return; }
        var that = this;

        if(this.headerActiveTO){ clearTimeout(this.headerActiveTO); }

        this.headerActiveTO = setTimeout(function(){
            that.model.set('expanded', true);
        }, 250);
    },

    headerInactiveMouseHandler: function(e){
        if(JS.view.isTouch() || JS.view.isMobileMenu()){ return; }
        var that = this;

        if(this.headerActiveTO){ clearTimeout(this.headerActiveTO); }

        this.headerActiveTO = setTimeout(function(){
            that.model.set('expanded', false);
        }, 250);
    },

    toggleHomeLayout: function(model, homeLayout){
        var that = this;

        this.model.set('homeLayoutVisible', false);
        this.model.set('homeLayoutOut', false);
        this.removeOpenDropdowns();

        if(!homeLayout){
            this.$el.addClass('fromHomeLayout');
            setTimeout(function(){
                that.$el.removeClass('fromHomeLayout');
            }, 250)
        }
        
        this.$el.toggleClass('homeLayout', homeLayout);

        this.model.set('expanded', false);
        this.setHeight(this.getHeight(), true);
    },

    toggleHomeLayoutVisible: function(model, visible){
        this.$el.toggleClass('homeLayoutVisible', visible);
    },

    toggleHomeLayoutOut: function(model, out){
        this.$el.toggleClass('homeLayoutOut', out);
    },

    toggleActiveLink: function(model, activeLink){
        this.$el.toggleClass('linkIsActive', _.isNumber(activeLink));
        this.$('a.galleryLink.active').removeClass('active');

        if(_.isNumber(activeLink)){
            this.$('a.galleryLink[post-id="'+activeLink+'"]').addClass('active');
        }
    },

    galleryActiveMouseHandler: function(e){
        if(JS.view.isTouch() || JS.view.isMobileMenu()){ return; }
        if(!this.model.get('homeLayoutVisible') || this.model.get('homeLayoutOut')){ return; }

        e.preventDefault();

        if(this.galleryInactiveTO){ clearTimeout(this.galleryInactiveTO); }
        
        var $e = $(e.currentTarget);
        var id = parseInt($e.attr('post-id'));

        this.model.set('activeLink', id);
    },

    galleryInactiveMouseHandler: function(e){
        if(JS.view.isTouch() || JS.view.isMobileMenu()){ return; }
        e.preventDefault();

        var that = this;

        if(this.galleryInactiveTO){ clearTimeout(this.galleryInactiveTO); }
        
        this.galleryInactiveTO = setTimeout(function(){
            that.model.set('activeLink', false);
        }, 100);
    },

    getOffset: function(){
        var off = 0;

        if(!this.model.get('homeLayout')){
            if(this.model.get('expanded')){
                off += 72;
            }

            off += this.getDropdownHeight();
        }

        return off;
    },

    getHeight: function(){
        var base = 0;


        if(JS.view.isMobileMenu() && (this.model.get('expanded') || this.model.get('homeLayout'))){
            base = JS.view.h;
        } else {
            if(this.model.get('homeLayout')){
                base += 238;
            } else {
                base += 120;
                if(this.model.get('expanded')){
                    base += 28;
                }
            }

            base += this.getDropdownHeight();
        }

        return base;
    },

    getDropdownHeight: function(){
        var base = 0;
        if(this.$('.hasDropdown.isActive').length > 0){
            var dropdownHeight = this.$('.hasDropdown.isActive:eq(0) .dropdown').height();
            base += dropdownHeight;
        }
        return base;   
    },

    setHeight: function(height, jump, delay){
        Velocity(this.$el, 'stop', true);

        if(jump){
            Velocity.hook(this.$el, 'height', height+'px');
        } else {
            Velocity(this.$el, {
                height: height
            }, {
                duration: 600,
                delay: delay ? delay : 0,
                queue: false
            });
        }

        JS.view.model.set('containerOffset', this.getOffset());
    },

    dropdownOpenMouseHandler: function(e){
        if(JS.view.isTouch() || JS.view.isMobileMenu()){ return; }

        this.removeOpenDropdowns();
        if(this.dropdownCloseTO){ clearTimeout(this.dropdownCloseTO); }

        var $e = $(e.currentTarget);
        this.openDropdown($e);  
    },

    dropdownCloseMouseHandler: function(e){
        if(JS.view.isTouch() || JS.view.isMobileMenu()){ return; }

        var that = this;

        if(this.dropdownCloseTO){ clearTimeout(this.dropdownCloseTO); }

        this.dropdownCloseTO = setTimeout(function(){
            that.removeOpenDropdowns();
            that.setHeight(that.getHeight());
        }, 100);
    },

    dropdownAClickHandler: function(e){
        var $e = $(e.currentTarget);

        var $dropdownParent = $e.closest('.hasDropdown');
        if(!$dropdownParent.hasClass('isActive')){
            e.preventDefault();
            e.stopPropagation();

            this.openDropdown($dropdownParent);
        }
    },

    openDropdown: function($el){
        $el.addClass('isActive');
        this.setHeight(this.getHeight());
    },

    removeOpenDropdowns: function(){
        this.$('.hasDropdown.isActive').removeClass('isActive');
    },

    resizeHeader: function(){
        if(JS.view.isMobileMenu()){
            this.setHeight(this.getHeight(), true);
        }
    },

    mobileMenuToggleHandler: function(e){
        e.preventDefault();
        e.stopPropagation();

        this.model.set('expanded', !this.model.get('expanded'));
    }
});

module.exports = HeaderView;