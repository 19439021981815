var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
var Velocity = require('velocity-animate');

var _basePage = require('./_basePage');

var preloadImages = require('../utils/preloadImages');
var imgSize = require('../utils/imgSize');
var breakpoints = require('../utils/breakpoints');

var Information = _basePage.fullExtend({

    initThis: function(){
        var that = this;

        if(this.$('.imageCol').length > 0){
            var $img = this.$('.imageCol img');
            preloadImages([$img.attr('src')]).done(function(){
                $img.addClass('loaded');
            });
        }
    },
});

module.exports = Information;