var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
var Velocity = require('velocity-animate');

var _basePage = require('./_basePage');

var preloadImages = require('../utils/preloadImages');
var imgSize = require('../utils/imgSize');

var GalleryView = require('../components/gallery');

var Gallery = _basePage.fullExtend({

    initThis: function(){
        var that = this;

        this.pubSub.on('page:removed', this.removeThis, this);

        this.galleries = [];
        this.$('.galleryBuild:eq(0)').each(function(){
            var $scope = $('.galleryScope', this);
            var array = that.buildGalleryArray($scope);
            var opts = {data: array, parent: that, start: 0, infinite: true, $parentEl: $(this)};
            var view = new GalleryView(opts);
            $(this).append(view.render().$el);
            $scope.remove();
            that.galleries.push(view);
            view.resize();
        });
    },

    animateIn: function(){
        var that = this;
        var promise = $.Deferred();
        
        this.$el.addClass('viewIn');
        setTimeout(function(){
            that.$el.addClass('titleOut');
        }, 800);
        setTimeout(function(){
            that.$('h1').remove();
            promise.resolve();
            that.pubSub.trigger('page:loaded');
        }, 1600);
        return promise.promise();
    },

    removeThis: function(){
        _.each(this.galleries, function(_gallery){
            _gallery.remove();
        });
    },

    fixedHeight: true
});

module.exports = Gallery;