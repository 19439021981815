var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
Backbone.View.fullExtend = require('../utils/Backbone.fullExtend');

var imgSize = require('../utils/imgSize');
var preloadImages = require('../utils/preloadImages');
var breakpoints = require('../utils/breakpoints');

var ImageView = Backbone.View.extend({
    className: 'image isLoading imgOut',
    
    template: _.template('<img src="<%= src %>" width="<%= width %>" height="<%= height %>" />'),
    
    initialize: function(opts){
        var that = this;
        this.parent = opts.parent;
        this.model = opts.model;

        this.preloadImage();
        
        JS.pubSub.on('app:resize', this.resize, this);
    },

    preloadImage: function(){
        var that = this;
        var src = [this.model.get('src')];
        preloadImages(src).done(function(){
            that.$el.removeClass('isLoading');
        });
    },

    render: function(){
        var template = this.template;
        var data = this.model.toJSON();
        this.$el.html(template(this.model.toJSON()));

        if(this.model.get('height') > this.model.get('width')){
            this.$el.addClass('portrait');
        }
        return this;
    },

    resize: function(){
        var that = this;
        var $el = this.$('img, iframe');
        
        $el.each(function(){
            var $_el = $(this);
            var opts = _.extend({}, that.returnImageOptions($_el), {
                $el: $_el
            });

            var dimensions = imgSize(opts);
            var max = that.getMaxDimensions();
            /*$el.css({
                'margin-left': max.width * -0.5,
            });*/

            // Velocity.hook($el, 'margin-left', max.width * -0.5 + 'px');
        });
    },

    returnImageOptions: function($img){
        var opts = {};
        var w = $img.attr('width');
        var h = $img.attr('height');

        opts.imgW = w;
        opts.imgH = h;

        var max = this.getMaxDimensions();
        opts.width = max.width;

        if(!JS.view.isMobile() || !JS.view.isLandscape()){ 
            opts.maxHeight = max.height;
        }

        return opts;
    },

    remove: function(){
        this.undelegateEvents();
        JS.pubSub.off('app:resize', this.resize, this);
        this.$el.remove();
    },

    getMaxDimensions: function(){
        var gutter = this.parent.getGutterWidth();
        var headerHeight = 76;
        var footerHeight = 72;
        if(JS.view.isMobile()){
            headerHeight = 56;
            footerHeight = 38;
        }
        return {
            width: JS.view.w - (gutter * 2),
            height: JS.view.h - headerHeight - footerHeight
        }
    }

});

module.exports = ImageView